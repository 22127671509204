var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page page register-page" },
    [
      _c("p", { staticClass: "login-title" }, [_vm._v(" 河南联创融久 ")]),
      _c("p", { staticClass: "login-title-less" }, [
        _vm._v(" 供应链管理系统 ")
      ]),
      _c(
        "van-row",
        { staticClass: "row-information" },
        [
          _c(
            "van-col",
            { attrs: { span: "16" } },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    block: "",
                    type: "info",
                    disabled: _vm.enterpriseAuth.orgBscAuthStatus === 1
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        path: "/personal/information/company",
                        query: { enterpriseId: _vm.enterpriseId }
                      })
                    }
                  }
                },
                [_vm._v(" 企业基本信息填报 ")]
              )
            ],
            1
          ),
          _c("van-col", { attrs: { span: "8" } }, [
            _c(
              "p",
              {
                class:
                  _vm.enterpriseAuth.orgBscAuthStatus === 1
                    ? "row-information-disabled"
                    : "row-information-p"
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.statusFormat(
                        _vm.enterpriseAuth.orgBscAuthStatus,
                        "authStatus"
                      )
                    ) +
                    " "
                )
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "van-row",
        { staticClass: "row-information" },
        [
          _c(
            "van-col",
            { attrs: { span: "16" } },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    block: "",
                    type: "info",
                    disabled:
                      _vm.enterpriseAuthAdmin.status === 1 ||
                      _vm.enterpriseAuth.orgBscAuthStatus === 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        path: "/personal/information/administrators",
                        query: { enterpriseId: _vm.enterpriseId }
                      })
                    }
                  }
                },
                [_vm._v(" 管理员身份信息填报 ")]
              )
            ],
            1
          ),
          _c("van-col", { attrs: { span: "8" } }, [
            _c(
              "p",
              {
                class:
                  _vm.enterpriseAuthAdmin.status === 1
                    ? "row-information-disabled"
                    : "row-information-p"
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.statusFormat(
                        _vm.enterpriseAuthAdmin.status,
                        "authStatus"
                      )
                    ) +
                    " "
                )
              ]
            )
          ])
        ],
        1
      ),
      _c("p", { staticClass: "examine-title" }, [
        _vm._v(" " + _vm._s(_vm.constants.examineTitle) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }