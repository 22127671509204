<template>
  <div class="login-page page register-page">
    <p class="login-title">
      河南联创融久
    </p>
    <p class="login-title-less">
      供应链管理系统
    </p>
    <van-row class="row-information">
      <van-col span="16">
        <van-button block type="info" :disabled="enterpriseAuth.orgBscAuthStatus===1" @click="$router.push({path:'/personal/information/company',query:{enterpriseId}})">
          企业基本信息填报
        </van-button>
      </van-col>
      <van-col span="8">
        <p :class="enterpriseAuth.orgBscAuthStatus===1?'row-information-disabled':'row-information-p'">
          {{ utils.statusFormat(enterpriseAuth.orgBscAuthStatus,'authStatus') }}
        </p>
      </van-col>
    </van-row>
    <van-row class="row-information">
      <van-col span="16">
        <van-button block type="info" :disabled="enterpriseAuthAdmin.status===1 || enterpriseAuth.orgBscAuthStatus===0" @click="$router.push({path:'/personal/information/administrators',query:{enterpriseId}})">
          管理员身份信息填报
        </van-button>
      </van-col>
      <van-col span="8">
        <p :class="enterpriseAuthAdmin.status===1?'row-information-disabled':'row-information-p'">
          {{ utils.statusFormat(enterpriseAuthAdmin.status,'authStatus') }}
        </p>
      </van-col>
    </van-row>
    <p class="examine-title">
      {{ constants.examineTitle }}
    </p>
  </div>
</template>

<script>
import { Field, Form, Button, NoticeBar, Col, Row } from 'vant'
export default {
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,
    [Col.name]: Col,
    [Row.name]: Row
  },
  data () {
    return {
      enterpriseId: this.$route.query.enterpriseId,
      enterpriseAuth: {},
      enterpriseAuthAdmin: {}
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.axios.all([this.api.base.enterpriseAuth(this.enterpriseId), this.api.base.enterpriseAuthAdmin(this.enterpriseId)]).then(this.axios.spread((enterpriseAuth, enterpriseAuthAdmin) => {
        this.enterpriseAuth = enterpriseAuth.data.value
        this.enterpriseAuthAdmin = enterpriseAuthAdmin.data.value
      }))
    }
  }
}
</script>

<style>

</style>
